:root {
  --background: rgb(25, 25, 25);
  --text: #ffffff;
  --text-low: rgba(255, 255, 255, 0.80);
  --text-gray: #505050;
  --color: #b00404;
  --color-low: rgba(255, 0, 0, 0.30);
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

body {
  background-color: var(--background);
  color: var(--text);
}


/*
 * ----------------------------------------
 * navbar
 * ----------------------------------------
*/
.navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 80px;
}

.navbar-brand {
  color: var(--color);
  font-size: large;
  font-size: 30px;
  font-weight: bold;

  -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.navbar .nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}

.navbar .nav ul {
  display: flex;
}

.navbar .nav ul li a {
  padding-left: 35px;
  color: var(--text);
  font-weight: 500;
  font-size: large;
  transition: color 500ms ease-in-out;
}

.unActived {
  position: relative;
}

.unActived::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color);
  transform-origin: center;
  transform: translate(-50%, 0) scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.unActived::before {
  transform: translate(-50%, 0) scaleX(1);
}

.actived {
  position: relative;
}

.actived::before {
  content: '';
  position: absolute;
  height: 2px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color);
  transform-origin: bottom left;
  transform: scaleX(1);
  transition: transform 0.3s ease-in-out;
}

.actived:hover::before {
  transform: scaleX(0);
  transform-origin: bottom right;
}


/*
 * ----------------------------------------
 * section landing
 * ----------------------------------------
*/
.landing {
  height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
}


.landing .container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}

.landing .container .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 200px;
  margin-top: 20px;
}

.landing .container .content h1 {
  color: var(--color);
  font-weight: bold;
}

.landing .container .content p {
  max-width: 600px;
  color: var(--text);
  margin-bottom: 20px;
}

.landing .container .content button{
  z-index: 1;
  position: relative;
  font-size: 20px;
  color: var(--text-low);
  padding: 0.5em 1em;
  outline: none;
  border: none;
  border-radius: 3px;
  background-color: var(--color-low);
  overflow: hidden;
  transition: color 0.4s ease-in-out;
}

.landing .container .content button::before {
  content: '';
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: var(--color);
  transform-origin: center;
  transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
  transition: transform 0.45s ease-in-out;
}

.landing .container .content button a:hover, .landing .container .content button:hover {
  cursor: pointer;
  color: #161616;
}

.landing .container .content button:hover::before {
  transform: translate3d(-50%, -50%, 0) scale3d(15, 15, 15);
}



/*
 * ----------------------------------------
 * media querys
 * ----------------------------------------
*/


@media screen and (max-width: 768px) {
  .landing .container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 16px; /* 25 default */
  }

  .landing .container .container {
    display: flex;
    align-items: center;
  }
  .landing .container .content {
    margin-right: 0px !important;
  }

  .landing .container .content p {
    max-width: 300px;
  }

  .landing .container img {
    max-width: 60%;
    margin-top: 50px;
    margin-bottom: -70px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .landing .container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 16px; /* 25 default */
  }

  .landing .container .content {
    margin-right: 0px !important;
  }

  .landing .container .content p {
    max-width: 300px;
  }

  .landing .container img {
    max-width: 60%;
    margin-top: 50px;
    margin-bottom: -70px;
  }
}

@media screen and (min-width: 1025px) {
  .landing .container {
    font-size: 25px; /* 25 default */
  }

  .landing .container .content {
    margin-bottom: 40px;

  }
  .landing .container .content p {
    max-width: 490px;
  }

  .landing .container img {
    max-width: 100%;
  }
}

/*
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
*/
 @-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}



